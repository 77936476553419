<template>
    <div>
        <div class="p-grid">
            <div class="p-col-8 p-offset-2">

                MODELO DE INFORMACIÓN POR CAPAS O NIVELES

                <p>
                    El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016,
                    relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos
                    personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE
                    (Reglamento general de protección de datos) (DOUE L 119/1, 04-05-2016) (en adelante, RGPD),
                    proporciona un marco modernizado y basado en la rendición de cuentas para la protección de los
                    datos en Europa.

                    En tal sentido, el artículo 12 del RGPD, bajo el epígrafe “Transparencia de la información,
                    comunicación y modalidades de ejercicio de los derechos del interesado”, establece lo siguiente
                    en su apartado 1:

                    El responsable del tratamiento tomará las medidas oportunas para facilitar al interesado toda
                    información indicada en los artículos 13 y 14, así como cualquier comunicación con arreglo a los
                    artículos 15 a 22 y 34 relativa al tratamiento, en forma concisa, transparente, inteligible y de
                    fácil acceso, con un lenguaje claro y sencillo, en particular cualquier información dirigida
                    específicamente a un niño. La información será facilitada por escrito o por otros medios,
                    inclusive, si procede, por medios electrónicos. Cuando lo solicite el interesado, la información
                    podrá facilitarse verbalmente siempre que se demuestre la identidad del interesado por otros
                    medios.
                </p>

                <p>
                    Para hacer compatible la mayor exigencia de información que introduce el RGPD y la concisión y
                    comprensión en la forma de presentarla, desde las Autoridades de Protección de Datos se
                    recomienda adoptar un modelo de información por capas o niveles.
                </p>

                <p>El enfoque de información multinivel consiste en lo siguiente:</p>

                <ul>
                    <li>- Presentar una información básica en un primer nivel, de forma resumida, en el mismo momento y
                        en el mismo medio en que se recojan los datos.</li>

                    <li> - Remitir a la información adicional en un segundo nivel, donde se presentarán detalladamente el
                        resto de las informaciones, en un medio más adecuado para su presentación, comprensión y, si se
                        desea, archivo.
                    </li>
                </ul>
            </div>
        </div>




        <div class="p-grid">
            <div class="p-col-8 p-offset-2">
                <br>
                <br>
                <h4>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS</h4>
            </div>
        </div>


        <div class="p-grid">
            <div class="p-col-8 p-offset-2 pt-5">
                <h4>Responsable del tratamiento::</h4>
                <p>Beatriz Ayllón De La Fuente</p>
                <h4>Dirección del responsable:</h4>
                <p>Avenida de Andalucía, 32, 1C - 28343 Valdemoro, Madrid</p>
                <h4>Finalidad:</h4>
                <p>Sus datos serán usados para poder atender sus solicitudes y prestarle nuestros servicios.</p>
                <h4>Publicidad:</h4>
                <p>Solo le enviaremos publicidad con su autorización previa, que podrá facilitarnos mediante la
                    casilla correspondiente establecida al efecto.</p>
                <h4>Legitimación:</h4>
                <p>Únicamente trataremos sus datos con su consentimiento previo, que podrá facilitarnos mediante
                    la casilla correspondiente establecida al efecto.</p>
                <h4>Destinatarios:</h4>
                <p>Con carácter general, sólo el personal de nuestra entidad que esté debidamente autorizado
                    podrá tener conocimiento de la información que le pedimos.</p>
                <h4>Derechos:</h4>
                <p>Tiene derecho a saber qué información tenemos sobre usted, corregirla y eliminarla, tal y
                    como se explica en la información adicional disponible en nuestra página web.</p>
                <h4>Información:</h4>
                <p>Más información en el apartado “SUS DATOS SEGUROS” de nuestra página web.</p>
            </div>
        </div>


        <div class="p-grid">
            <div class="p-col-8 p-offset-2">
                <h3>INFORMACIÓN AMPLIADA SOBRE PROTECCIÓN DE DATOS</h3>
                <h3>SUS DATOS SEGUROS</h3>
                <p>Información en cumplimiento de la normativa de protección de datos personales</p>
                <br>
                <p>En Europa y en España existen normas de protección de datos pensadas para proteger su información
                    personal de obligado cumplimiento para nuestra entidad.</p>
                <p>Por ello, es muy importante para nosotros que entienda perfectamente qué vamos a hacer con los
                    datos personales que le pedimos.</p>
                <p>Así, seremos transparentes y le daremos el control de sus datos, con un lenguaje sencillo y
                    opciones claras que le permitirán decidir qué haremos con su información personal.</p>
                <p>Por favor, si una vez leída la presente información le queda alguna duda, no dude en
                    preguntarnos.</p>
                <p>Muchas gracias por su colaboración.</p>

                <p>• ¿Quiénes somos?</p>
                <p>• Nuestra denominación: Beatriz Ayllón De La Fuente</p>

                <p>• Nuestro CIF / NIF: 47048599Y</p>

                <p>• Nuestra actividad principal: Clínica / Profesional de la medicina</p>

                <p>• Nuestra dirección: Paseo de los Hoteles, 2, CP 28341, Valdemoro (Madrid)</p>

                <p>• Nuestro teléfono de contacto: 637857384</p>

                <p>• Nuestra dirección de correo electrónico de contacto: psicoeduca.formacion@gmail.com</p>

                <p>• Nuestra página web: </p>

                <p>• Para su confianza y seguridad, le informamos que somos una entidad inscrita en el siguiente
                    Registro Mercantil /Registro Público:</p>

                <p>• Nuestra actividad principal está sujeta a un régimen de autorización administrativa previa.
                    Para su confianza y seguridad, le facilitamos los datos relativos a dicha autorización y los
                    identificativos del órgano competente de nuestra supervisión:</p>
                <p>Autorización administrativa:</p>
                <p>Órgano encargado de nuestra supervisión:</p>
                <p>(EN CASO DE QUE LA ACTIVIDAD ESTÉ SUJETA A UN RÉGIMEN DE AUTORIZACIÓN ADMINISTRATIVA)</p>

                <p>• El responsable de esta página web ejerce una profesión regulada, para lo cual le facilitamos la
                    siguiente información:</p>
                <p>Colegio profesional al que pertenece: </p>
                <p>Título académico oficial o profesional:</p>
                <p>Estado de la Unión Europea en que se expidió dicho título:</p>
                <p>Normas profesionales aplicables al ejercicio de la profesión:</p>
                <p>(EN CASO DE QUE EL RESPONSABLE DE LA PÁGINA WEB EJERZA UNA PROFESIÓN REGULADA)</p>

                <p>Estamos a su disposición, no dude en contactar con nosotros.</p>

                <p>• ¿Para qué vamos a usar sus datos?</p>
                <p>Con carácter general, sus datos personales serán usados para poder relacionarnos con usted y
                    poder prestarle nuestros servicios.</p>
                <p>Asimismo, también pueden ser usados para otras actividades, como enviarle publicidad o
                    promocionar nuestras actividades. </p>

                <p>• ¿Por qué necesitamos usar sus datos?</p>
                <p>Sus datos personales son necesarios para poder relacionarnos con usted y poder prestarle nuestros
                    servicios. En este sentido, pondremos a su disposición una serie de casillas que le permitirán
                    decidir de manera clara y sencilla sobre el uso de su información personal. </p>

                <p>• ¿Quién va a conocer la información que le pedimos?</p>
                <p>Con carácter general, sólo el personal de nuestra entidad que esté debidamente autorizado podrá
                    tener conocimiento de la información que le pedimos.</p>
                <p>De igual modo, podrán tener conocimiento de su información personal aquellas entidades que
                    necesiten tener acceso a la misma para que podamos prestarle nuestros servicios. Así por
                    ejemplo, nuestro banco conocerá sus datos si el pago de nuestros servicios se realiza mediante
                    tarjeta o transferencia bancaria.</p>
                <p>Asimismo, tendrán conocimiento de su información aquellas entidades públicas o privadas a las
                    cuales estemos obligados a facilitar sus datos personales con motivo del cumplimiento de alguna
                    ley. Poniéndole un ejemplo, la Ley Tributaria obliga a facilitar a la Agencia Tributaria
                    determinada información sobre operaciones económicas que superen una determinada cantidad.</p>
                <p>En el caso de que, al margen de los supuestos comentados, necesitemos dar a conocer su
                    información personal a otras entidades, le solicitaremos previamente su permiso a través de
                    opciones claras que le permitirán decidir a este respecto.</p>

                <p>• ¿Cómo vamos a proteger sus datos?</p>
                <p>Protegeremos sus datos con medidas de seguridad eficaces en función de los riesgos que conlleve
                    el uso de su información.</p>
                <p>Para ello, nuestra entidad ha aprobado una Política de Protección de Datos y se realizan
                    controles y auditorías anuales para verificar que sus datos personales están seguros en todo
                    momento.</p>

                <p>• ¿Enviaremos sus datos a otros países?</p>
                <p>En el mundo hay países que son seguros para sus datos y otros que no lo son tanto. Así por
                    ejemplo, la Unión Europea es un entorno seguro para sus datos. Nuestra política es no enviar su
                    información personal a ningún país que no sea seguro desde el punto de vista de la protección de
                    sus datos.</p>
                <p>En el caso de que, con motivo de prestarle el servicio, sea imprescindible enviar sus datos a un
                    país que no sea tan seguro como España, siempre le solicitaremos previamente su permiso y
                    aplicaremos medidas de seguridad eficaces que reduzcan los riesgos del envío de su información
                    personal a otro país. </p>

                <p>• ¿Durante cuánto tiempo vamos a conservar sus datos?</p>
                <p>Conservaremos sus datos durante nuestra relación y mientras nos obliguen las leyes. Una vez
                    finalizados los plazos legales aplicables, procederemos a eliminarlos de forma segura y
                    respetuosa con el medio ambiente. </p>

                <p>• ¿Cuáles son sus derechos de protección de datos?</p>
                <p>En cualquier momento puede dirigirse a nosotros para saber qué información tenemos sobre usted,
                    rectificarla si fuese incorrecta y eliminarla una vez finalizada nuestra relación, en el caso de
                    que ello sea legalmente posible. </p>
                <p>También tiene derecho a solicitar el traspaso de su información a otra entidad. Este derecho se
                    llama “portabilidad” y puede ser útil en determinadas situaciones.</p>
                <p>Para solicitar alguno de estos derechos, deberá realizar una solicitud escrita a nuestra
                    dirección, junto con una fotocopia de su DNI, para poder identificarle. </p>
                <p>En las oficinas de nuestra entidad disponemos de formularios específicos para solicitar dichos
                    derechos y le ofrecemos nuestra ayuda para su cumplimentación.</p>
                <p>Para saber más sobre sus derechos de protección de datos, puede consultar la página web de la
                    Agencia Española de Protección de Datos (www.agpd.es).</p>

                <p>• ¿Puede retirar su consentimiento si cambia de opinión en un momento posterior?</p>
                <p>Usted puede retirar su consentimiento si cambia de opinión sobre el uso de sus datos en cualquier
                    momento. </p>
                <p>Así por ejemplo, si usted en su día estuvo interesado/a en recibir publicidad de nuestros
                    productos o servicios, pero ya no desea recibir más publicidad, puede hacérnoslo constar a
                    través del formulario de oposición al tratamiento disponible en las oficinas de nuestra entidad.
                </p>

                <p>• En caso de que entienda que sus derechos han sido desatendidos, ¿dónde puede formular una
                    reclamación?</p>
                <p>En caso de que entienda que sus derechos han sido desatendidos por nuestra entidad, puede
                    formular una reclamación en la Agencia Española de Protección de Datos, a través de alguno de
                    los medios siguientes:</p>
                <p>o Sede electrónica: www.agpd.es</p>
                <p>o Dirección postal:</p>
                <p>Agencia Española de Protección de Datos</p>
                <p>C/ Jorge Juan, 6</p>
                <p>28001-Madrid </p>
                <p>o Vía telefónica:</p>
                <p>Telf. 901 100 099 </p>
                <p>Telf. 91 266 35 17</p>
                <p>Formular una reclamación en la Agencia Española de Protección de Datos no conlleva ningún coste y
                    no es necesaria la asistencia de abogado ni procurador. </p>

                <p>• ¿Elaboraremos perfiles sobre usted?</p>
                <p>Nuestra política es no elaborar perfiles sobre los usuarios de nuestros servicios.</p>
                <p>No obstante, pueden existir situaciones en las que, con fines de prestación del servicio,
                    comerciales o de otro tipo, necesitemos elaborar perfiles de información sobre usted. Un ejemplo
                    pudiera ser la utilización de su historial de compras o servicios para poder ofrecerle productos
                    o servicios adaptados a sus gustos o necesidades.</p>
                <p>En tal caso, aplicaremos medidas de seguridad eficaces que protejan su información en todo
                    momento de personas no autorizadas que pretendan utilizarla en su propio beneficio. </p>

                <p>• ¿Usaremos sus datos para otros fines?</p>
                <p>Nuestra política es no usar sus datos para otras finalidades distintas a las que le hemos
                    explicado. Si, no obstante, necesitásemos usar sus datos para actividades distintas, siempre le
                    solicitaremos previamente su permiso a través de opciones claras que le permitirán decidir al
                    respecto.</p>
             
                <h3>CASILLAS OBTENCIÓN CONSENTIMIENTO</h3>

                <h3>PERMISOS (MARQUE LA CASILLA CORRESPONDIENTE EN CASO AFIRMATIVO):</h3>
                <p>  Consiento el uso de mis datos para los fines indicados en la política de privacidad “SUS
                    DATOS SEGUROS”.</p>
                <p>  Consiento el uso de mis datos personales para recibir publicidad de su entidad.</p>




             
                <h3>POLÍTICA DE PROTECCIÓN DE DATOS DE LA ENTIDAD</h3>
                <p>Esta información, dirigida a todas las personas (usuarias o no de la página web), deberá
                    facilitarse a través de un enlace disponible en la página web, bajo la denominación “POLÍTICA DE
                    PROTECCIÓN DE DATOS”. Dicho enlace deberá estar disponible, preferiblemente, en la parte de
                    arriba de la página web, para facilitar su visibilidad y accesibilidad.</p>

                <h3>POLÍTICA DE PROTECCIÓN DE DATOS</h3>

                <p>La Dirección / Órgano de Gobierno de Beatriz Ayllón De La Fuente (en adelante, el responsable del
                    tratamiento), asume la máxima responsabilidad y compromiso con el establecimiento,
                    implementación y mantenimiento de la presente Política de Protección de Datos, garantizando la
                    mejora continua del responsable del tratamiento con el objetivo de alcanzar la excelencia en
                    relación con el cumplimiento del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo,
                    de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al
                    tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga
                    la Directiva 95/46/CE (Reglamento general de protección de datos) (DOUE L 119/1, 04-05-2016), y
                    de la normativa española de protección de datos de carácter personal (Ley Orgánica, legislación
                    sectorial específica y sus normas de desarrollo).</p>

                <p>La Política de Protección de Datos de Beatriz Ayllón De La Fuente descansa en el principio de
                    responsabilidad proactiva, según el cual el responsable del tratamiento es responsable del
                    cumplimiento del marco normativo y jurisprudencial que gobierna dicha Política, y es capaz de
                    demostrarlo ante las autoridades de control competentes.</p>

                <p>En tal sentido, el responsable del tratamiento se regirá por los siguientes principios que deben
                    servir a todo su personal como guía y marco de referencia en el tratamiento de datos personales:
                </p>

                <p>1. Protección de datos desde el diseño: el responsable del tratamiento aplicará, tanto en el
                    momento de determinar los medios de tratamiento como en el momento del propio tratamiento,
                    medidas técnicas y organizativas apropiadas, como la seudonimización, concebidas para aplicar de
                    forma efectiva los principios de protección de datos, como la minimización de datos, e integrar
                    las garantías necesarias en el tratamiento.</p>

                <p>2. Protección de datos por defecto: el responsable del tratamiento aplicará las medidas técnicas
                    y organizativas apropiadas con miras a garantizar que, por defecto, solo sean objeto de
                    tratamiento los datos personales que sean necesarios para cada uno de los fines específicos del
                    tratamiento.</p>

                <p>3. Protección de datos en el ciclo de vida de la información: las medidas que garanticen la
                    protección de los datos personales serán aplicables durante el ciclo completo de la vida de la
                    información.</p>

                <p>4. Licitud, lealtad y transparencia: los datos personales serán tratados de manera lícita, leal y
                    transparente en relación con el interesado. </p>

                <p>5. Limitación de la finalidad: los datos personales serán recogidos con fines determinados,
                    explícitos y legítimos, y no serán tratados ulteriormente de manera incompatible con dichos
                    fines.</p>

                <p>6. Minimización de datos: los datos personales serán adecuados, pertinentes y limitados a lo
                    necesario en relación con los fines para los que son tratados.</p>

                <p>7. Exactitud: los datos personales serán exactos y, si fuera necesario, actualizados; se
                    adoptarán todas las medidas razonables para que se supriman o rectifiquen sin dilación los datos
                    personales que sean inexactos con respecto a los fines para los que se tratan.</p>

                <p>8. Limitación del plazo de conservación: los datos personales serán mantenidos de forma que se
                    permita la identificación de los interesados durante no más tiempo del necesario para los fines
                    del tratamiento de los datos personales.</p>

                <p>9. Integridad y confidencialidad: los datos personales serán tratados de tal manera que se
                    garantice una seguridad adecuada de los datos personales, incluida la protección contra el
                    tratamiento no autorizado o ilícito y contra su pérdida, destrucción o daño accidental, mediante
                    la aplicación de medidas técnicas u organizativas apropiadas. </p>

                <p>10. Información y formación: una de las claves para garantizar la protección de los datos
                    personales es la formación e información que se facilite al personal involucrado en el
                    tratamiento de los mismos. Durante el ciclo de vida de la información, todo el personal con
                    acceso a los datos será convenientemente formado e informado acerca de sus obligaciones en
                    relación con el cumplimiento de la normativa de protección de datos.</p>

                <p>La Política de Protección de Datos de Beatriz Ayllón De La Fuente es comunicada a todo el
                    personal del responsable del tratamiento y puesta a disposición de todas las partes interesadas.
                </p>

                <p>En su consecuencia, la presente Política de Protección de Datos involucra a todo el personal del
                    responsable del tratamiento, que debe conocerla y asumirla, considerándola como propia, siendo
                    cada miembro responsable de aplicarla y de verificar las normas de protección de datos
                    aplicables a su actividad, así como identificar y aportar las oportunidades de mejora que
                    considere oportunas con el objetivo de alcanzar la excelencia en relación con su cumplimiento.
                </p>

                <p>Esta Política será revisada por la Dirección / Órgano de Gobierno de Beatriz Ayllón De La Fuente,
                    tantas veces como se considere necesario, para adecuarse, en todo momento, a las disposiciones
                    vigentes en materia de protección de datos de carácter personal.</p>
            </div>
        </div>



    </div>
</div></template>

<script>
export default {
    name: 'PrivacidadVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>

